.navbar {
    position: fixed;
    z-index: 10;
    top: 0;
    width: 100%;
    display: flex;
    height: 75px;
    background-color: rgba(0, 0, 0, 0.25);
    color: var(--primary_350);
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
}

.navbar > .logo {
    display: flex;
    margin: auto;
    margin-right: 0;
    margin-left: 50px;
}

.navbar > .logo > .icon-container {
    padding-top: 5px;
    margin: auto;
    margin-right: 5px;
}

.navbar > .items-container {
    margin: auto;
    margin-left: 50px;
    display: flex;
    gap: 64px;
}

.nav--item {
    position: relative;
    text-decoration: none;
    color: var(--primary_350)
}

.nav--item.selected::after {
    transform: scaleX(1);
}

.nav--item::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--jgp_white);
    bottom: -4px;
    left: 0;
    transform: scaleX(0);
    transition: transform 350ms var(--cb);
}

.nav--item:hover::after {
    transform: scaleX(1);
}

.navbar > .socials {
    float: right;
    display: flex;
    margin-right: 50px;
}

.navbar > .socials > .social-icon {
    color: white;
    text-decoration: none;
    margin: auto;
    margin-left: 10px;
    margin-right: 10px;
    transition: scale 550ms var(--cb);
}

.navbar > .socials > .social-icon:hover {
    scale: 1.2;
}

.navbar > .socials > .social-icon:visited {
    text-decoration: none;
    color: white
}

@media only screen and (max-width: 1040px) {
    .navbar {
        width: 100%;
    }

    .navbar > .items-container {
        display: none;
    }

    .navbar > .logo {
        margin-left: 15px;
    }

    .navbar > .socials {
        margin-right: 15px;
        margin-left: auto;
    }
}

@media only screen and (min-width: 1041px) {
    .navbar {
        height: 100px;
    }
    
    .navbar > .items-container {
        gap: 28px;
    }
}

@media only screen and (min-width: 1920px) {
    .navbar {
        height: 125px;
    }

    .navbar > .logo > .icon-container > svg {
        font-size: larger;
    }

    .navbar > .items-container {
        gap: 32px;
    }
}

@media only screen and (min-width: 2560px) {
    .navbar {
        height: 150px;
    }

    .navbar > .logo > .icon-container > svg {
        font-size: xxx-large;
        padding-top: 5px;
    }

    .navbar > .socials > .social-icon > svg {
        font-size: larger;
    }

    .navbar > .items-container {
        gap: 64px;
    }
}

