.prices-section {
    display: flex;
    scroll-margin: 5vh;
    gap: 32px;
}

.prices-section > .content-container {
    width: 100%;
}

.prices-section > .image-container {
    width: 100%;
    display: flex;
}

.prices-section > .image-container > .image-box {
    position: relative;
    margin: auto;
}

.prices-section > .image-container > .image-box > .border-dark {
    top: -25px;
    left: -75px;
}

.prices-section > .image-container > .image-box > .border-light {
    right: -75px;
}

.prices-section > .image-container .image {
    width: 500px;
    height: 500px;
}

.prices-section > .content-container {
    display: flex;
}

.prices-section > .content-container > .content {
    margin: auto;
    display: flex;
    flex-direction: column;
}

.prices-section > .content-container > .content > h1 {
    margin-top: 0;
}

.prices-section > .content-container > .content > .text {
    width: 75%;
    text-align: left;
    margin: auto;
    margin-bottom: 50px;
}

.prices-section > .content-container > .content > .prices--container {
    overflow: hidden;
    max-height: 0px;
    transition: max-height 550ms var(--cb);
    margin-bottom: 32px;
}

.prices-section > .content-container > .content > .prices--container.open {
    max-height: 800px;
}

@media only screen and (max-width: 1040px) {
    .prices-section {
        flex-direction: column;
        gap: 0px;
    }

    .prices-section > .image-container .image {
        width: 300px;
        height: 300px;
    }

    .prices-section > .image-container {
        margin-top: 125px;
    }

    .prices-section > .image-container > .image-box > .border-light {
        right: -25px;
    }

    .prices-section > .image-container > .image-box > .border-dark {
        top: -55px;
        left: -35px;
    }
}

/* @media only screen and (min-width: 1041px) {
    .prices-section > .content-container > .content > .forward-button > svg {
        font-size: large;
        padding-top: 5px;
    }
}

@media only screen and (min-width: 1920px) {
    .prices-section > .content-container > .content > .forward-button > svg {
        font-size: larger;
        padding-top: 1px;
    }
}

@media only screen and (min-width: 2560px) {
    .prices-section > .content-container > .content > .forward-button > svg {
        font-size: xx-large;
        padding-top: 3px;
    }
} */