.header {
    height: fit-content;
}

.header > .banner {
    position: relative;
    height: 100vh;
}

.header > .banner > .banner-image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: 5;
}

.header > .banner > .gradient {
    position: absolute;
    height: 25vh;
    width: 100%;
    bottom: 0px;
    z-index: 7;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 25%, var(--primary_350));
}

.scroll-down-container {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
}

.scroll-down-1 {
    position: absolute;
    bottom: 7.5vh;
    display: block;
    text-align: center;
    font-size: 20px;
    z-index: 100;
    text-decoration: none;
    text-shadow: 0;
    width: 25px;
    height: 25px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    z-index: 9;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-animation: fade_move_down 4s ease-in-out infinite;
    -moz-animation:    fade_move_down 4s ease-in-out infinite;
    animation:         fade_move_down 4s ease-in-out infinite;
}

.scroll-down-2 {
    position: absolute;
    bottom: 5vh;
    display: block;
    text-align: center;
    font-size: 20px;
    z-index: 100;
    text-decoration: none;
    text-shadow: 0;
    width: 25px;
    height: 25px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    z-index: 9;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-animation: fade_move_down 4s ease-in-out infinite;
    -moz-animation:    fade_move_down 4s ease-in-out infinite;
    animation:         fade_move_down 4s ease-in-out infinite;
}

.scroll-down-3 {
    position: absolute;
    bottom: 2.5vh;
    display: block;
    text-align: center;
    font-size: 20px;
    z-index: 100;
    text-decoration: none;
    text-shadow: 0;
    width: 25px;
    height: 25px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    z-index: 9;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-animation: fade_move_down 4s ease-in-out infinite;
    -moz-animation:    fade_move_down 4s ease-in-out infinite;
    animation:         fade_move_down 4s ease-in-out infinite;
}

/*animated scroll arrow animation*/
@-webkit-keyframes fade_move_down {
    0%   { -webkit-transform:translate(0,-10px) rotate(45deg); opacity: 0;  }
    50%  { opacity: 1;  }
    100% { -webkit-transform:translate(0,10px) rotate(45deg); opacity: 0; }
  }
  @-moz-keyframes fade_move_down {
    0%   { -moz-transform:translate(0,-10px) rotate(45deg); opacity: 0;  }
    50%  { opacity: 1;  }
    100% { -moz-transform:translate(0,10px) rotate(45deg); opacity: 0; }
  }
  @keyframes fade_move_down {
    0%   { transform:translate(0,-10px) rotate(45deg); opacity: 0;  }
    50%  { opacity: 1;  }
    100% { transform:translate(0,10px) rotate(45deg); opacity: 0; }
  }

@media (prefers-color-scheme: dark) {
    .header > .banner > .gradient {
        background-image: linear-gradient(rgba(0, 0, 0, 0) 25%, var(--primary_300));
    }
}