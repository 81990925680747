.marquee-section {
    width: 100%;
}

.marquee-section > .marquee-container {
    margin-top: 25px;
    margin-bottom: 25px;
    height: 150px;
    display: flex;
    justify-content: space-around;
}

.segment {
    display: flex;
    height: 120px;
    width: 300px;
    background-color: gray;
    border-radius: 15px;
    box-shadow: 10px 10px 5px var(--primary_305);
}

@media (prefers-color-scheme: dark) {
	.segment {
        box-shadow: 10px 10px 5px var(--primary_302);
    }
}

.segments-container {
    display: flex;
    width: 100vw;
    justify-content: space-around;
}

.segment > .segment-content {
    margin: auto;
    text-align: left;
}

.segment > .segment-content > .quote {
    color: white;
    font-size: 24px;
    margin-bottom: 10px;
}

.segment > .segment-content > .author {
    font-style: italic;
    color: white;
    margin-top: 0;
    font-size: 16px;
}