input::placeholder {
    color: var(--primary_305);
}

.contact-section {
    height: fit-content;
    margin-bottom: 0;
    scroll-margin: 10vh;
}

.contact-section > .contact-data > .maps-embed {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

.contact-section > .contact-data > .maps-embed > .map {
    width: 800px; 
    height: 450px;
}

.contact-section > .contact-data > .content-container {
    display: flex;
    flex-direction: column;
}

.contact-section > .contact-data > .content-container > .text {
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.contact-section > .contact-data > .content-container > .text > .text--item{
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
}

.contact-section > .contact-me {
    scroll-margin: 15vh;
    display: flex;
}

.contact-section > .contact-me > .left {
    width: 40%;
}

.contact-section > .contact-me > .left > .contact-data {
    width: fit-content;
    margin: auto;
    margin-top: 50px;
    text-align: left;
}

.contact-section > .contact-me > .left > .contact-data > h4 {
    margin: 0;
}

.contact-section > .contact-me > .left > .contact-data > p {
    margin: 0;
}

.contact-section > .contact-me > .left > .contact-data {
    width: fit-content;
}

.contact-section > .contact-me > .left > .image-container > .image {
    margin-top: 25px;
    width: 75px;
    height: 75px;
    border-radius: 50%;
}

.contact-section > .contact-me > .right {
    width: 60%;
    margin: auto;
}

.contact-section > .contact-me > .right > h2 {
    text-align: left;
    width: fit-content;
}

.contact-section > .contact-me > .right > p {
    width: fit-content;
}

.contact-section > .contact-me > .right > .contact-form > .input-container {
    width: 100%;
    text-align: left;
}

.contact-section > .contact-me > .right > .contact-form > .input-container > .input-field {
    width: 92.5%;
    text-align: left;
}

.contact-section > .contact-me > .right > .contact-form > .top {
    display: flex;
}

.contact-section > .contact-me > .right > .contact-form > .top > .input-container {
    width: 100%;
    text-align: left;
}

.contact-section > .contact-me > .right > .form-submit {
    text-align: left;
}

.contact-section > .contact-me > .right > .form-submit > .button {
    border: 2px solid var(--primary_300);
    padding: 10px 20px;
}

.contact-section > .contact-me > .right > .form-submit > .button:hover {
    border: 2px solid var(--primary_300);
    background-color: var(--primary_305);
    padding: 10px 20px;
}

.input-hint {
    font-size: 12px;
}

.input-field {
    margin: auto;
    border: none;
    background-color: var(--primary_340);
    padding: 2px 15px;
    width: 85%;
    height: 45px;
    font-size: 20px;
}

@media only screen and (max-width: 1040px) {
    .contact-section > .contact-data > .maps-embed > .map {
        width: stretch; 
        height: 450px;
    }

    .contact-section > .contact-me > .left {
        display: none;
    }

    .contact-section > .contact-me > .right {
        width: 100%;
    }

    .contact-section > .contact-me > .right > .contact-form > .top {
        flex-direction: column;
    }

    .input-container > .input-field {
        width: stretch;
        text-align: left;
    }

    .contact-section > .contact-me > .right > .contact-form > .input-container > .input-field {
        width: stretch;
        text-align: left;
    }
}

@media only screen and (min-width: 1041px) {
    .contact-section > .contact-me > .right > .form-submit > .checkbox {
        width: 15px;
        height: 15px;
    }
}

@media only screen and (min-width: 1920px) {
    .contact-section > .contact-me > .right > .form-submit > .checkbox {
        width: 20px;
        height: 20px;
    }
}

@media only screen and (min-width: 2560px) {
    .contact-section > .contact-me > .right > .form-submit > .checkbox {
        width: 25px;
        height: 25px;
    }
}