.footer {
    position: relative;
    min-height: 150px;
    background-color: #757575;
    color: var(--primary_350);
}

.footer > .top-row {
    padding-top: 25px;
    display: flex;
    height: 70%;
}

.footer > .top-row > .socials {
    display: flex;
    margin-left: 50px;
}

.footer > .top-row > .socials > .social-icon {
    color: white;
    text-decoration: none;
    margin: auto;
    margin-left: 10px;
    margin-right: 10px;
    transition: scale 550ms var(--cb);
}

.footer > .top-row > .socials > .social-icon:hover {
    scale: 1.2;
}

.footer > .top-row > .socials > .social-icon:visited {
    text-decoration: none;
    color: white
}

.footer > .top-row > .links {
    margin: auto;
    margin-left: 25px;
}

.footer > .top-row > .links > .link {
    position: relative;
    margin-right: 25px;
    margin-left: 25px;
}

.footer > .top-row > .links > .link.selected::after {
    transform: scaleX(1);
}

.footer > .top-row > .links > .link:hover::after {
    transform: scaleX(1);
}

.footer > .top-row > .links > .link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--jgp_white);
    bottom: -4px;
    left: 0;
    transform: scaleX(0);
    transition: transform 350ms var(--cb);
}

.footer > .top-row > .links > .link:hover {
    cursor: pointer;
}

.footer > .top-row > .logo {
    display: flex;
    margin: auto;
    margin-right: 50px;
    margin-left: 25px;
}

.footer > .top-row > .logo > .icon-container {
    padding-top: 5px;
    margin: auto;
    margin-right: 5px;
}

.footer > .content-container {
    overflow: hidden;
    max-height: 0px;
    transition: max-height 550ms var(--cb);
}

.footer > .content-container.open {
    max-height: 1440px;
    transition: max-height 550ms var(--cb);
}

.footer > .copyright {
    font-size: 10pt;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #757575;
    color: var(--primary_310);
}

@media (prefers-color-scheme: dark) {
	.footer {
		background-color: #111111;
	}

    .footer > .copyright {
        background-color: #111111;
    }
}

@media only screen and (max-width: 1040px) {
    .footer > .top-row {
        display: flex;
        flex-direction: column;
    }

    .footer > .top-row > .socials {
        margin: auto;
        margin-bottom: 25px;
    }

    .footer > .top-row > .links {
        margin: auto;
        display: flex;
        flex-direction: column;
    }

    .footer > .top-row > .links > a {
        margin-bottom: 15px;
    }

    .footer > .top-row > .logo {
        margin: auto;
        margin-bottom: 55px;
    }
}

@media only screen and (min-width: 1920px) {
    .footer > .top-row > .logo > .icon-container > svg {
        font-size: larger;
        padding-top: 5px;
    }

    .footer > .top-row > .socials > .social-icon > svg {
        font-size: larger;
    }
}

@media only screen and (min-width: 2560px) {
    .footer > .top-row > .logo > .icon-container > svg {
        font-size: xxx-large;
        padding-top: 5px;
    }
}