.imprint-container {
    padding-top: 25px;
    padding-bottom: 50px;
    background-color: #7e7e7e;
    display: block;
}

.imprint-content {
    text-align: left;
    margin: auto;
    width: 75%;
    margin-top: 15px;
}

@media (prefers-color-scheme: dark) {
    .imprint-container {
        background-color: #191919;
    }
}