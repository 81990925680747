.pricelist-section {
    margin-top: 50px;
}

.pricelist-section > .pricelist {
    display: flex;
    justify-content: space-between;
}

.pricelist-section > .pricelist > .price-category {
    display: flex;
    width: 20%;
    background-color: var(--primary_340);
    border: 2px solid var(--primary_305);
    border-radius: 15px;
    padding: 20px;
    margin-left: auto; 
    margin-right: auto;
    box-shadow: 10px 10px 15px var(--primary_305);
}

.pricelist-section > .pricelist > .price-category > .category-container {
    margin: auto;
    width: 65%;
}

.pricelist-section > .disclaimer {
    margin-top: 35px;
}

.pricelist-section > .disclaimer > p > .link {
    color: var(--primary_302);
    text-decoration: none;
}

.button--outline {
    text-decoration: none;
}

@media (prefers-color-scheme: dark) {
    .pricelist-section > .pricelist > .price-category {
        background-color: var(--primary_300);
        border: 2px solid var(--primary_340);
        box-shadow: 10px 10px 15px var(--primary_302);
    }
}

@media only screen and (max-width: 1040px) {
    .pricelist-section > .pricelist > .price-category {
        width: 25%;
        padding: 5px;
        font-size: 10px;
    }
}