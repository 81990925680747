.instagram-section {
    display: flex;
}

.instagram-section > .ig-embed {
    display: flex;
    width: 100%;
}

.instagram-section > .content-container {
    width: 100%;
    display: flex;
}

.instagram-section > .content-container > .content {
    margin: auto;
}

.instagram-section > .ig-embed > .embed {
    margin: auto;
    width: 600px;
    height: 605px;
    border-radius: 15px;
    box-shadow: 20px 15px 15px var(--primary_305);
}

@media (prefers-color-scheme: dark) {
	.instagram-section > .ig-embed > .embed {
        box-shadow: 20px 15px 15px var(--primary_302);
    }
}

@media only screen and (max-width: 1040px) {
    .instagram-section {
        flex-direction: column;
    }

    .instagram-section > .ig-embed > .embed {
        margin-top: 25px;
        width: stretch;
        height: calc(85vw);
    }
}