.portfolio-section {
    display: flex;
}

.portfolio-section > .content-container {
    width: 100%;
}

.portfolio-section > .image-container {
    width: 100%;
    display: flex;
}

.portfolio-section > .image-container > .image-box {
    position: relative;
    margin: auto;
}

.portfolio-section > .image-container > .image-box > .border-dark {
    left: 125px;
}

.portfolio-section > .image-container > .image-box > .border-light {
    left: -35px;
}

.portfolio-section > .image-container .image {
    width: 500px;
    height: 500px;
}

.portfolio-section > .content-container {
    display: flex;
}

.portfolio-section > .content-container > .content {
    margin: auto;
}

.portfolio-section > .content-container > .content > h1 {
    margin-top: 0;
}

.portfolio-section > .content-container > .content > .text {
    width: 50%;
    margin: auto;
    text-align: center;
}

.portfolio-section > .content-container > .content > ul {
    width: fit-content;
    margin: auto;
    padding-left: 0;
    margin-top: 10px;
    margin-bottom: 25px;
}

@media only screen and (max-width: 1040px) {
    .portfolio-section {
        flex-direction: column;
        flex-flow: column-reverse;
        margin-top: 25px;
    }

    .portfolio-section > .content-container {
        margin: auto;
    }

    .portfolio-section > .image-container .image {
        width: 300px;
        height: 300px;
    }

    .portfolio-section > .image-container {
        margin: auto;
    }

    .portfolio-section > .image-container > .image-box > .border-dark {
        left: 25px;
    }
}