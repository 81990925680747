html {
    scroll-behavior: smooth;
}

body {
	overscroll-behavior-x: none;
    overscroll-behavior-y: none;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

:root {
	--primary_300: rgb(0, 0, 0);
	--primary_302: rgb(85, 85, 85);
	--primary_305: rgba(160, 165, 170, 1);
	--primary_310: rgba(172, 172, 172,1);
	--primary_320: rgba(181, 181, 181, 1);
	--primary_340: rgba(243, 244, 245, 1);
	--primary_350: rgba(255, 255, 255, 1);
	--jgp_white: white;
	--cb: cubic-bezier(.21, .4, .48, 1);
}

section {
	scroll-margin: 2vh;
	width: 90%;
	min-height: 90vh;
	margin: auto;
	margin-top: 25px;
	margin-bottom: 25px;
}

p {
	color: var(--primary_320);
}

.image-container {
	position: relative;
}

.border-dark {
	border: 2px solid black;
	position: absolute;
	top: -15px;
	left: -20px;
	z-index: -5;
	width: 500px;
	height: 500px;
}

.border-light {
	border: 2px solid gray;
	position: absolute;
	top: 25px;
	right: -25px;
	z-index: -5;
	width: 500px;
	height: 500px;
}

img {
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.button--outline {
	text-wrap: nowrap;
	margin: auto;
	display: flex;
	gap: 6px;
	justify-content: center;
	align-items: center;
	width: fit-content;
	background-color: transparent;
	color: var(--jgp_white);
	font-weight: bold;
	border: 2px solid var(--jgp_white);
	padding: 16px 24px;
	transition: background-color 350ms var(--cb), color 350ms var(--cb);
}

.button--outline:hover {
	cursor: pointer;
	background-color: var(--jgp_white);
	color: var(--primary_300);
}

.button--icon {
	width: 24px;
	height: 24px;
	fill: var(--jgp_white);
	transition: transform 350ms var(--cb), fill 350ms var(--cb);
}

.button--outline:hover > .button--icon {
	fill: var(--primary_300);
	transform: translateX(12px);
}

@media (prefers-color-scheme: dark) {
	.border-dark {
		border: 2px solid white;
	}

	body {
		color: var(--primary_350);
		background-color: var(--primary_300);
	}

	h1, h2, h3, h4 {
		color: var(--primary_350);
	}
}

@media only screen and (max-width: 1040px) {
	section {
		margin-top: 120px;
		margin-bottom: 120px;
	}

	.border-dark {
		width: 300px;
		height: 300px;
	}
	
	.border-light {
		width: 300px;
		height: 300px;
	}
}

@media (min-width: 1041px) {
	body {
	  font-size: 20px;
	}

	.button--icon {
		width: 32px;
		height: 32px;
	}
}

@media (min-width: 1920px) {
	body {
	  font-size: 26px;
	}

	.button--icon {
		width: 36px;
		height: 36px;
	}
}

@media (min-width: 2560px) {
	body {
	  font-size: 32px;
	}

	.button--icon {
		width: 48px;
		height: 48px;
	}
}