.about-section {
    scroll-margin: 7vh;
    display: flex;
    margin-bottom: 100px;
}

.about-section > .content-container {
    margin: auto;
    width: 100%;
}

.about-section > .content-container > .image-container {
    margin-bottom: 25px;
}

.about-section > .content-container > .image-container > .image {
    width: 500px;
}

.about-section > .content-container > .text {
    width: 45%;
    text-align: center;
    margin: auto;
}

@media only screen and (max-width: 1040px) {
    .about-section > .content-container > .text {
        width: 85%;
    }

    .about-section > .content-container > .image-container > .image {
        width: 300px;
    }
}
