.home-section {
    display: flex;
}

.home-section > .content-container {
    width: 100%;
}

.home-section > .image-container {
    width: 100%;
    display: flex;
}

.home-section > .image-container > .image-box {
    position: relative;
    margin: auto;
}

.home-section > .image-container .image {
    width: 500px;
    height: 500px;
}

.home-section > .content-container {
    display: flex;
}

.home-section > .content-container > .content {
    margin: auto;
}

.home-section > .content-container > .content > h1 {
    margin-top: 0;
}

.home-section > .content-container > .content > .text {
    width: fit-content;
    text-align: left;
    margin: auto;
}

@media only screen and (max-width: 1040px) {
    .home-section {
        flex-direction: column;
        margin-bottom: 50px;
    }

    .home-section > .image-container .image {
        width: 300px;
        height: 300px;
    }

    .home-section > .image-container {
        margin: auto;
    }
}